import React from "react";
import { StaticImage } from "gatsby-plugin-image";
import { Seo } from "../components/seo";
import Layout from "../components/layout";
import HireBanner from "../components/hire/HireBanner";
import OurWorkslef from "../components/hire/OurWorkself";
import CommonServiceComponent from "../components/service/CommonServiceComponent";
import Faq from "../components/Faq";
import { Helmet } from "react-helmet";

const HirePage = () => {
  const banner = {
    title: `Banking Design Agency`,
    text: "Banking Website Design",
    content: "We are a specialized banking web design agency creating engaging digital experiences for banks and financial institutions. Our experts craft custom websites, apps, and platforms optimized for functionality, security, and compliance to streamline your operations through intuitive, customer-centric design.",
    list: [
      'Specialized Financial Expertise',
      'Customer-centric Approach',
      'Optimized Digital Solutions'
    ]
  };
  const ourData = {
    title: `Clients Review On Our <span class='h1-span'>Banking Website Design</span>`,
    list1: [
      {
        logo: ['intellect.webp'],
        stars: 5,
        text: 'Octet has been very supportive to our design and technical requirements. Their designers and developers have always helped us to deliver creative and on-time solutions to our marketing needs.',
        name: 'Mariam Khan',
        des: 'Head Of Digital Marketing'
      },
      {
        logo: ['adani.webp'],
        stars: 5,
        text: "Octet Designers were instrumental in transforming our internal products and processes.",
        name: 'Naveen Kumar',
        des: 'QA Manager'
      },
      {
        logo: ['intellect.webp'],
        stars: 4,
        text: "The team's flexibility and understanding of our business needs impressed us with their fast turnaround.",
        name: 'Ishan',
        des: 'Group Product Manager'
      }
    ],
    list2: [
      {
        logo: ['visa.webp'],
        stars: 5,
        text: 'With so many things we could be doing in our product, it was hard to streamline and decide where to invest our time and effort.',
        name: 'Vipin D',
        des: 'Customer Success Manager'
      },
      {
        logo: ['intellect.webp'],
        stars: 4,
        text: 'Using Design first approach with Octet’s designers gave us more clarity and streamlined our solution and delivery.',
        name: 'Todd Baughman',
        des: 'SVP Global Operations'
      },
      {
        logo: ['intellect.webp'],
        stars: 5,
        text: 'They have delivered more than what they have committed. They made our sophisticated platform super simple and classic, our customers love using our app than any competitors.',
        name: 'Parag Chivate',
        des: 'Founder and CEO'
      },
    ]
  };
  const commonContent = {
    title: "Empowering Banks With Our <span class='h1-span'>Banking Design Services</span>",
    content: [
      {
        title: "10+ Team of Experts",
        text: "We have a dedicated team of over 10+ highly skilled professionals, including designers, developers, and financial industry consultants committed to delivering exceptional banking website solutions.",
      },
      {
        title: "80+ Successful Projects",
        text: "With a portfolio of over 80+ successful projects in the banking sector, we have a proven track record of designing and launching high-performing websites for banks and financial institutions worldwide.",
      },
      {
        title: "90% Client Retention Rates",
        text: "Our focus on quality and highly functional designs has resulted in an impressive 90% client retention rate, showcasing our ability to build lasting relationships and continually meet client expectations.",
      },
      {
        title: "95% Satisfaction Rates",
        text: "We are committed to delivering top-notch service, reflected in our 95% client satisfaction rate, with clients consistently appreciating our attention to detail, innovative solutions, and exceptional customer support.",
      },
    ],
  };
  const commonContent1 = {
    title: "Unlock Potential With Our Banking Design Agency",
    content: [
      {
        title: "Banking Design Expertise",
        text: "We understand the unique requirements, regulations, and challenges banking and financial institutions face and create tailored solutions that address their specific needs.",
      },
      {
        title: "Data-Driven Insights",
        text: "We leverage user research to gain a deep understanding of the target audience and incorporate these insights, leading to higher user engagement and satisfaction.",
      },
      {
        title: "Regulatory Compliance",
        text: "We follow the latest guidelines and standards set by governing bodies, ensuring that all our design solutions comply with and meet the necessary regulatory requirements.",
      },
      {
        title: "Collaborative Partnership",
        text: "We work closely with your team to understand your unique goals and customer needs to deliver exceptional results that are aligned with your strategic objectives.",
      },
      {
        title: "Scalable Designs",
        text: "Our banking design agency creates scalable and adaptable design systems, ensuring your user experience remains consistent and effective as your organization expands.",
      },
      {
        title: "Omnichannel Design Solution",
        text: "We have experience designing omnichannel solutions that provide a cohesive and integrated user journey and ensure enhanced user satisfaction across all channels.",
      },
    ],
  };
  const Questions = [
    "What banking design services do you offer?",
    "What is your design process?",
    "Can you integrate your designs with our existing banking systems?",
    "How do you measure the success of your designs?",
    "What is your pricing structure?"
  ];
  const faqData = [
    {
        para: [
            `We provide comprehensive banking design services, including branding, user interface (UI) design, website design, mobile app design, and digital marketing collateral.`,
            `Our experienced designers and strategists work closely with banks and financial institutions to create visually appealing and user-friendly designs that enhance the customer experience.`
        ]
    },
    {
      para: [
        `Our design process starts with thoroughly understanding your brand, target audience, and business goals.`,
        `We conduct user research and competitive analysis to identify opportunities and develop a tailored design strategy.`,
        `Our designers then create wireframes, mockups, and prototypes, which we iteratively refine based on feedback from you and your team.`,
        `Once the designs are approved, we provide you with the necessary assets and guidelines to ensure consistent implementation across all touchpoints.`
      ],
    },
    {
      para: [
        `Absolutely. We have extensive experience integrating our designs with various banking platforms, core systems, etc.`,
        `Our team collaborates closely with your team to ensure a seamless integration and a cohesive user experience across all your digital touchpoints.`
      ]
    },
    {
      para: [
        `We use a combination of quantitative and qualitative metrics to evaluate the success of our designs.`,
        `This includes tracking key performance indicators (KPIs) such as user engagement, conversion rates, and customer satisfaction.`,
        `We also gather employee feedback to improve and optimize the designs continuously.`
      ],
    },
    {
        para: [
          `Our pricing is tailored to the specific needs of each project and can vary based on the scope of work, the complexity of the designs, and the timeline.`,
          `We provide transparent and competitive pricing and are happy to discuss the details with you during our initial consultation. <a title="Contact us" target="_blank" href="/contact-us/">Contact us</a> now.`
        ],
    }
  ];
  return (
    <>
    <Helmet>
      <script type="application/ld+json">
        {`
        {
            "@context": "https://schema.org",
            "@type": "FAQPage",
            "mainEntity": [{
              "@type": "Question",
              "name": "What banking design services do you offer?",
              "acceptedAnswer": {
                "@type": "Answer",
                "text": "We provide comprehensive banking design services, including branding, user interface (UI) design, website design, mobile app design, and digital marketing collateral.
          
          Our experienced designers and strategists work closely with banks and financial institutions to create visually appealing and user-friendly designs that enhance the customer experience."
              }
            },{
              "@type": "Question",
              "name": "What is your design process?",
              "acceptedAnswer": {
                "@type": "Answer",
                "text": "Our design process starts with thoroughly understanding your brand, target audience, and business goals.
          
          We conduct user research and competitive analysis to identify opportunities and develop a tailored design strategy.
          
          Our designers then create wireframes, mockups, and prototypes, which we iteratively refine based on feedback from you and your team.
          
          Once the designs are approved, we provide you with the necessary assets and guidelines to ensure consistent implementation across all touchpoints."
              }
            },{
              "@type": "Question",
              "name": "Can you integrate your designs with our existing banking systems?",
              "acceptedAnswer": {
                "@type": "Answer",
                "text": "Absolutely. We have extensive experience integrating our designs with various banking platforms, core systems, etc.
          
          Our team collaborates closely with your team to ensure a seamless integration and a cohesive user experience across all your digital touchpoints."
              }
            },{
              "@type": "Question",
              "name": "How do you measure the success of your designs?",
              "acceptedAnswer": {
                "@type": "Answer",
                "text": "We use a combination of quantitative and qualitative metrics to evaluate the success of our designs.
          
          This includes tracking key performance indicators (KPIs) such as user engagement, conversion rates, and customer satisfaction.
          
          We also gather employee feedback to improve and optimize the designs continuously."
              }
            },{
              "@type": "Question",
              "name": "What is your pricing structure?",
              "acceptedAnswer": {
                "@type": "Answer",
                "text": "Our pricing is tailored to the specific needs of each project and can vary based on the scope of work, the complexity of the designs, and the timeline.
          
          We provide transparent and competitive pricing and are happy to discuss the details with you during our initial consultation. Contact us now."
              }
            }]
          }                                                       
        `}
      </script>
    </Helmet>
    <Layout hire="Banking Design Agency" hotjarHire={true} footerText="Maximize your conversions with Us">
      <div>
        <HireBanner data={banner} hire pageName="Hire Conversion Rate Optimisation" />
        <section className="py-[40px]">
          <div className="marquee lg:h-[100px] md:h-[80px] h-[60px]">
            <div className="lg:h-[100px] md:h-[80px] h-[60px]">
              <div className="flex items-center">
                <div className="marquee--logo w-[calc(100vw/3)] lg:w-[calc(100vw/8)]">
                  <StaticImage className="max-w-[100%] h-auto w-[67px]" title="visa" alt="visa" src="../images/visa.webp" />
                </div>
                <div className="marquee--logo w-[calc(100vw/3)] lg:w-[calc(100vw/8)]">
                  <StaticImage className="max-w-[100%] h-auto w-[91px]" title="lode-stone" alt="lode-stone" src="../images/lode-stone.webp" />
                </div>
                <div className="marquee--logo w-[calc(100vw/3)] lg:w-[calc(100vw/8)]">
                  <StaticImage className="max-w-[100%] h-auto w-[69px]" title="adani" alt="adani" src="../images/adani.webp" />
                </div>
                <div className="marquee--logo w-[calc(100vw/3)] lg:w-[calc(100vw/8)]">
                  <StaticImage className="max-w-[100%] h-auto w-[102px]" title="tvs" alt="tvs" src="../images/tvs.webp" />
                </div>
                <div className="marquee--logo w-[calc(100vw/3)] lg:w-[calc(100vw/8)]">
                  <StaticImage className="max-w-[100%] h-auto w-[53px]" title="iima" alt="iima" src="../images/iima.webp" />
                </div>
                <div className="marquee--logo w-[calc(100vw/3)] lg:w-[calc(100vw/8)]">
                  <StaticImage className="max-w-[100%] h-auto w-[116.89px]" title="intellect" alt="intellect" src="../images/intellect.webp" />
                </div>
                <div className="marquee--logo w-[calc(100vw/3)] lg:w-[calc(100vw/8)]">
                  <StaticImage className="max-w-[100%] h-auto w-[122px]" title="aditya-birla" alt="aditya-birla" src="../images/aditya-birla.webp" />
                </div>
                <div className="marquee--logo w-[calc(100vw/3)] lg:w-[calc(100vw/8)]">
                  <StaticImage className="max-w-[100%] h-auto w-[73px]" title="kantime" alt="kantime" src="../images/kantime.webp" />
                </div>
              </div>
              <div className="flex items-center">
                <div className="marquee--logo w-[calc(100vw/3)] lg:w-[calc(100vw/8)]">
                  <StaticImage className="max-w-[100%] h-auto w-[67px]" title="visa" alt="visa" src="../images/visa.webp" />
                </div>
                <div className="marquee--logo w-[calc(100vw/3)] lg:w-[calc(100vw/8)]">
                  <StaticImage className="max-w-[100%] h-auto w-[91px]" title="lode-stone" alt="lode-stone" src="../images/lode-stone.webp" />
                </div>
                <div className="marquee--logo w-[calc(100vw/3)] lg:w-[calc(100vw/8)]">
                  <StaticImage className="max-w-[100%] h-auto w-[69px]" title="adani" alt="adani" src="../images/adani.webp" />
                </div>
                <div className="marquee--logo w-[calc(100vw/3)] lg:w-[calc(100vw/8)]">
                  <StaticImage className="max-w-[100%] h-auto w-[102px]" title="tvs" alt="tvs" src="../images/tvs.webp" />
                </div>
                <div className="marquee--logo w-[calc(100vw/3)] lg:w-[calc(100vw/8)]">
                  <StaticImage className="max-w-[100%] h-auto w-[53px]" title="iima" alt="iima" src="../images/iima.webp" />
                </div>
                <div className="marquee--logo w-[calc(100vw/3)] lg:w-[calc(100vw/8)]">
                  <StaticImage className="max-w-[100%] h-auto w-[116.89px]" title="intellect" alt="intellect" src="../images/intellect.webp" />
                </div>
                <div className="marquee--logo w-[calc(100vw/3)] lg:w-[calc(100vw/8)]">
                  <StaticImage className="max-w-[100%] h-auto w-[122px]" title="aditya-birla" alt="aditya-birla" src="../images/aditya-birla.webp" />
                </div>
                <div className="marquee--logo w-[calc(100vw/3)] lg:w-[calc(100vw/8)]">
                  <StaticImage className="max-w-[100%] h-auto w-[73px]" title="kantime" alt="kantime" src="../images/kantime.webp" />
                </div>
              </div>
            </div>
          </div>
        </section>
        <OurWorkslef data={ourData} hire />
        <CommonServiceComponent data={commonContent} mode={"dark"} hire />
        <CommonServiceComponent data={commonContent1} mode={"light"} hire />
        <Faq grey section Questions={Questions} faqData={faqData} des={''} />
      </div>
    </Layout>
    </>
  );
};

export default HirePage;

export const Head = () => (
  <Seo
    title="Banking Design Agency | Banking Website Design"
    description="Elevate your bank's digital presence with our banking design agency. We craft visually stunning, user-friendly websites that drive conversions."
  />
);
